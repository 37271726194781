import Link from "next/link";
import { useRouter } from "next/router";

import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { signOut, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import {
  HelpOutline as HelpOutlineIcon,
  KeyboardArrowRightRounded as KeyboardArrowRightIcon,
  Logout as LogoutIcon,
  Message as MessageIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";

import { NavigationContext, NavigationPath, UserAvatar } from "@components";
import { loadTranslations } from "@lib";
import { Logger } from "@utils";

import { SearchBar } from "./search-bar";

const pathNameDetector = (path: string, currentPath: string) => {
  // Remove lang prefix from current path name.
  currentPath = currentPath.replace("/[lang]", "");

  // Check if the path is a subset of the current path.
  return currentPath.startsWith(path);
};

export const NavigationBar = () => {
  const { paths, navigationComponent } = useContext(NavigationContext);
  const { pathname: routerPathName } = useRouter();

  // Filter out paths that don't have a match in the current pathname.
  // Return them ordered by size - longer pathname appear last.
  const pathsArray = useMemo(() => {
    const filteredPathNames = Object.entries(paths).reduce(
      (acc, [pathName, props]) => {
        if (!pathNameDetector(pathName, routerPathName)) {
          Logger.error(`[NavigationContext] path ${pathName} does not match current path ${routerPathName}`);
          return acc;
        }

        return [...acc, { pathName, props }];
      },
      [] as { pathName: string; props: NavigationPath }[],
    );

    return filteredPathNames.sort((a, b) => a.pathName.length - b.pathName.length);
  }, [routerPathName, paths]);

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="center"
      flexWrap="nowrap"
      width="100%"
      position="sticky"
      top="0"
      zIndex={900}
      padding="var(--space-12) var(--space-24)"
      gap="var(--size-16)"
      sx={{
        backgroundColor: "var(--color-palette-base-000)",
        borderWidth: "0 0 thin 0",
        borderStyle: "solid",
        borderColor: "var(--color-palette-pastel-purple)",
      }}
    >
      <Stack flexDirection="row" gap="var(--size-8)" alignItems="center">
        {navigationComponent ??
          pathsArray.map(({ pathName, props }, index, array) => (
            <NavigationBarItem
              key={pathName}
              pathName={pathName}
              render={props.render}
              redirectTo={props.redirectTo}
              isLast={index === array.length - 1}
            />
          ))}
      </Stack>
      <NavigationBarSideMenu />
    </Stack>
  );
};

const NavigationBarItem: FC<{ pathName: string; render: ReactNode; redirectTo?: string; isLast: boolean }> = ({
  pathName,
  render,
  redirectTo,
  isLast,
}) => (
  <>
    <Link href={redirectTo ?? pathName}>
      <Typography
        fontSize="var(--font-size-100)"
        color={isLast ? "var(--color-palette-base-800)" : "var(--color-palette-base-500)"}
        fontWeight={isLast ? 700 : 400}
        lineHeight="150%"
        sx={{ cursor: "pointer", textDecoration: "none", maxWidth: "40ch", textOverflow: "ellipsis" }}
        flexShrink={0}
      >
        {render}
      </Typography>
    </Link>
    {isLast ? null : (
      <KeyboardArrowRightIcon
        sx={{
          display: "block",
          margin: "auto 0",
          // MUI path is (weirdly) not centered in the original icon.
          paddingTop: "0.1rem",
          fontSize: "1.8rem",
          fill: "var(--color-palette-base-400)",
        }}
        color="inherit"
        fontSize="inherit"
      />
    )}
  </>
);

interface NavigationBarIconMenuProps {
  children: ReactElement;
  toolTip?: ReactElement;
  menu: ReactElement;
}

const NavigationBarIconMenu: FC<NavigationBarIconMenuProps> = ({ children, menu, toolTip }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenuOpen = useCallback(() => setMenuOpen((prev) => !prev), []);

  // Close menu when clicking anywhere on the page (except the button).
  useEffect(() => {
    const autoClose = (e: MouseEvent) => {
      if (!menuRef.current?.contains(e.target as Node)) setMenuOpen(false);
    };

    document.addEventListener("click", autoClose);
    return () => document.removeEventListener("click", autoClose);
  }, []);

  return (
    <Box position="relative" ref={menuRef} sx={{ cursor: "pointer" }} onClick={toggleMenuOpen}>
      {toolTip ? (
        <Tooltip title={toolTip} placement="bottom-end">
          {children}
        </Tooltip>
      ) : (
        children
      )}
      <Box
        position="absolute"
        top="calc(100% + var(--space-16))"
        right="0"
        zIndex={99}
        sx={{
          transition: "opacity 0.2s",
          opacity: menuOpen ? 1 : 0,
          // Prevent blocking mouse clicks under the modal when hidden.
          pointerEvents: menuOpen ? "auto" : "none",
          borderRadius: "var(--space-16)",
          padding: "var(--space-16)",
          cursor: "default",
          backgroundColor: "var(--color-palette-base-000)",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;",
        }}
      >
        {menu}
      </Box>
    </Box>
  );
};

const HelpMenu = () => {
  const { t } = useTranslation(["navigation"]);
  loadTranslations("navigation");

  const { data: session } = useSession();
  const mailHREF = `mailto:${t("navigation:support.contactContent.mailto")}?subject=${encodeURIComponent(
    `${t("navigation:support.contactContent.message")} - [ ${String(session?.user.groups[0])} ]  - ${t("navigation:support.contactContent.:subject")}`,
  )}`;

  return (
    <Stack flexDirection="column" width="16rem" gap="var(--space-8)">
      <Typography fontWeight={700} color="var(--color-palette-base-800)" fontSize="var(--font-size-125)">
        {t("navigation:support.title")}
      </Typography>
      <Divider />
      <Link
        href={mailHREF}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "var(--space-8)",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <MessageIcon sx={{ color: "var(--color-palette-base-800)", fontSize: "var(--font-size-150)" }} />
        <Typography color="var(--color-palette-base-800)" fontSize="var(--font-size-85)">
          {t("navigation:support.contact")}
        </Typography>
      </Link>
      <Divider />
      <Stack flexDirection="row" flexWrap="nowrap" gap="var(--space-8)" alignItems="center">
        <Link href={t("navigation:support.policy.link")}>
          <Typography color="var(--color-palette-base-800)" fontSize="var(--font-size-85)">
            {t("navigation:support.policy.title")}
          </Typography>
        </Link>
        <Link href={t("navigation:support.termsOfUse.link")}>
          <Typography color="var(--color-palette-base-800)" fontSize="var(--font-size-85)">
            {t("navigation:support.termsOfUse.title")}
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

const ProfileLink: FC<{ onClick: () => void; icon: ReactNode; children: ReactNode }> = ({
  onClick,
  icon,
  children,
}) => (
  <Stack
    onClick={onClick}
    flexDirection="row"
    alignItems="center"
    gap="var(--space-16)"
    padding="var(--space-6)"
    flexWrap="nowrap"
    sx={{
      cursor: "pointer",
      borderRadius: "var(--size-4)",
      "&:hover": {
        backgroundColor: "var(--color-palette-base-100)",
      },
    }}
  >
    <Typography fontSize="var(--font-size-100)" color="var(--color-palette-base-800)">
      {icon}
    </Typography>
    <Typography lineHeight="100%" fontSize="var(--font-size-75)" color="var(--color-palette-base-800)">
      {children}
    </Typography>
  </Stack>
);

const ProfileMenu = () => {
  const { t } = useTranslation(["navigation"]);
  loadTranslations("navigation");

  const { data: session } = useSession();
  const router = useRouter();

  const signout = useCallback(async () => {
    const idToken = session?.idToken;
    idToken && (await signOut({ callbackUrl: `/api/auth/logout?id_token=${idToken}` }));
  }, [session?.idToken]);

  return (
    <Stack flexDirection="column" alignItems="stretch" width="16rem" gap="var(--space-8)">
      <Stack flexDirection="row" gap="var(--space-16)" alignItems="center" flexWrap="nowrap">
        <UserAvatar size="2rem" sx={{ flexShrink: 0 }} />
        <Stack flexDirection="column" flexGrow={1} gap={0}>
          <Typography
            lineHeight="120%"
            color="var(--color-palette-base-800)"
            fontSize="var(--font-size-100)"
            fontWeight={700}
          >
            {session?.user.given_name} {session?.user.family_name}
          </Typography>
          <Typography
            lineHeight="120%"
            color="var(--color-palette-base-800)"
            fontSize="var(--font-size-85)"
            fontWeight={400}
          >
            {session?.user.groups[0]}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack flexDirection="column" alignItems="stretch" gap={0}>
        <ProfileLink
          onClick={() => {
            router.push("/personal-settings").catch(Logger.error);
          }}
          icon={<PersonIcon sx={{ display: "block", margin: "auto 0" }} />}
        >
          {t("navigation:profile.profile")}
        </ProfileLink>
        <ProfileLink
          onClick={() => {
            router.push("/organization-settings").catch(Logger.error);
          }}
          icon={<SettingsIcon sx={{ display: "block", margin: "auto 0" }} />}
        >
          {t("navigation:profile.organization")}
        </ProfileLink>
        <ProfileLink
          onClick={() => {
            signout().catch(Logger.error);
          }}
          icon={<LogoutIcon sx={{ display: "block", margin: "auto 0" }} />}
        >
          {t("navigation:profile.logout")}
        </ProfileLink>
      </Stack>
    </Stack>
  );
};

const NavigationBarSideMenu = () => {
  const { t } = useTranslation(["navigation"]);
  loadTranslations("navigation");

  return (
    <Stack gap="var(--space-24)" flexDirection="row" alignItems="center">
      <SearchBar />
      <NavigationBarIconMenu menu={<HelpMenu />} toolTip={t("navigation:support.toolTip")}>
        {/* https://github.com/mui/material-ui/issues/31261#issuecomment-1057478910 */}
        <>
          <HelpOutlineIcon
            sx={{
              display: "block",
              margin: "auto 0",
              color: "var(--color-palette-base-800)",
              fontSize: "var(--font-size-200)",
            }}
          />
        </>
      </NavigationBarIconMenu>
      <NavigationBarIconMenu menu={<ProfileMenu />} toolTip={t("navigation:profile.toolTip")}>
        <>
          <UserAvatar size="3rem" />
        </>
      </NavigationBarIconMenu>
    </Stack>
  );
};
