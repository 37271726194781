import { useRouter } from "next/router";

import React, { useEffect, useState } from "react";

import { debounce } from "lodash";
import { useTranslation } from "next-i18next";

import { PersonSearch as PersonSearchIcon } from "@mui/icons-material";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { FONT_SIZE, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";

import { useOTELContext, useOTELSpan, useUserGroup } from "@hooks";
import { useSearchApplicationsInOrganization } from "@hooks/queries";

export const SearchBar = () => {
  const { t } = useTranslation(["search-bar"]);
  loadTranslations("search-bar");

  const [inputValue, setInputValue] = useState<string>("");

  const queryClient = useQueryClient();

  const router = useRouter();

  const { ctx } = useOTELSpan(useOTELContext(), "applications");

  const organizationName = useUserGroup();

  const { applications, refetch, isSuccess } = useSearchApplicationsInOrganization(ctx, organizationName, {
    q: inputValue,
    limit: 10,
  });

  useEffect(() => {
    if (inputValue.length >= 3) {
      queryClient.cancelQueries({ queryKey: [QUERY_KEYS.APPLICATIONS_SEARCH, { organizationName }] });
      refetch();
    } else {
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.APPLICATIONS_SEARCH, { organizationName }] });
    }
  }, [inputValue, organizationName, queryClient, refetch]);

  const debouncedChange = debounce(setInputValue, 300);

  return (
    <Autocomplete
      getOptionLabel={(o) =>
        `${o.candidate?.last_name} ${o.candidate?.last_name} / ${o.candidate?.email} / ${o.candidate?.phone} / ${o.job?.title} / ${o.job?.location_state}`
      }
      renderOption={(props, o) =>
        isSuccess ? (
          <Box component="li" {...props} sx={{ fontSize: FONT_SIZE[75] }}>
            {o.candidate?.last_name} / {o.candidate?.email} / {o.candidate?.phone} / {o.job?.title} / {o.job?.city}
          </Box>
        ) : null
      }
      options={inputValue === "" ? [] : applications}
      noOptionsText={<Box sx={{ fontSize: FONT_SIZE[85] }}>{t("noResultFound")}</Box>}
      renderInput={(params) => (
        <TextField
          {...params}
          hiddenLabel
          placeholder={t("placeholder")}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PersonSearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      onChange={(_, option, reason) => {
        if (reason === "selectOption" && option !== null) {
          router.replace(`/applications/${option.id}`);
        }
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          debouncedChange(value);
        }
      }}
      filterOptions={(options) => options}
      sx={{ width: "500px" }}
      clearOnBlur={false}
    />
  );
};
