import Image from "next/image";

import { Theme } from "@mui/material";

import { Job } from "@typings";

export const JobTitle = ({ job, theme }: { job: Job; theme: Theme }) => {
  return (
    <>
      {job?.title}
      <br />
      <span style={{ fontSize: "20px", fontWeight: "normal", position: "relative", bottom: "12px", right: "5px" }}>
        <Image
          src="/assets/icons/location.svg"
          alt="location-icon"
          color={theme.palette.orange?.main}
          style={{ position: "relative", top: "5px" }}
          width="24"
          height="24"
        />{" "}
        {job?.city}
      </span>
    </>
  );
};
