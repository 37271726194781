import { useRouter } from "next/router";

import { FC, ReactNode, useContext, useEffect } from "react";

import { TOptions } from "i18next";
import { useTranslation } from "next-i18next";

import { ArrowBackSharp as ArrowBackSharpIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { loadTranslations } from "@lib";

import { NavigationContext, NavigationPath } from "./context";

/**
 * Add a new entry to the navigation bar. This entry will automatically match the current path with the given pathName,
 * to control display order.
 *
 * The pathname must be the FULL pathname the rendered content will redirect to, excluding URL search parameters.
 * Display is the text that will appear as a link.
 *
 * The pathname value must either be a subset of, or strictly equal to the current pathname. If the pathname cannot
 * be found in the current URI, then the display will not be rendered.
 *
 * The pathname provided can be empty, in which case it will not be rendered either.
 */
export const useNavigationPath = (pathName: string, props: NavigationPath) => {
  const { addPath, removePath } = useContext(NavigationContext);

  useEffect(() => {
    if (!pathName) return;
    addPath(pathName, {
      render: props.render,
      redirectTo: props.redirectTo,
    });
    return () => removePath(pathName);
    // Pass props separately, so they can be properly compared by React.
  }, [pathName, props.render, props.redirectTo, addPath, removePath]);
};

/**
 * Replace navigation paths by a static component of your choice. Only one static component can be set at a time.
 */
export const useNavigationComponent = (component: ReactNode) => {
  const { setNavigationComponent, removeNavigationComponent } = useContext(NavigationContext);

  useEffect(() => {
    setNavigationComponent(component);
    return removeNavigationComponent;
  }, [component, setNavigationComponent, removeNavigationComponent]);
};

/**
 * Component to statically declare a navigation bar header. This will not render anything, but will add the path to the
 * navigation bar.
 */
export const NavigationBarHeader: FC<{ pathName: string; redirectTo?: string; tsOptions?: TOptions }> = ({
  pathName,
  redirectTo,
  tsOptions,
}) => {
  const { t } = useTranslation(["navigation"]);
  loadTranslations("navigation");

  useNavigationPath(pathName, {
    render: t(`navigation:${pathName}`, tsOptions),
    redirectTo,
  });

  return null;
};

/**
 * Component to statically declare a navigation bar header. This will not render anything, but will add the path to the
 * navigation bar.
 */
export const NavigationBarHeaderComponent: FC<{ children: ReactNode }> = ({ children }) => {
  useNavigationComponent(children);

  return null;
};

export const NavigationHeaderBackComponent: FC = () => {
  const { t } = useTranslation(["navigation"]);
  loadTranslations("navigation");

  const router = useRouter();

  return (
    <NavigationBarHeaderComponent>
      <Stack
        flexDirection="row"
        gap="var(--size-8)"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => router.back()}
      >
        <Typography fontSize="var(--font-size-100)" color="var(--color-palette-base-500)">
          <ArrowBackSharpIcon fontSize="inherit" style={{ display: "block", margin: "auto 0" }} />
        </Typography>
        <Typography fontSize="var(--font-size-100)" color="var(--color-palette-base-500)">
          {t("navigation:back")}
        </Typography>
      </Stack>
    </NavigationBarHeaderComponent>
  );
};
