import { CallHistory, Interview, InterviewWasDone, Job, MessagingHistoryReminder, RecruiterReminder } from "@typings";

export interface Application {
  id: string;

  campaign_id: string;
  candidate_id: string;
  organization_name: string;
  date: string;

  // job info
  job_id: string;
  job_title: string;
  state: string;
  city: string;
  region: string;
  postal_code: string;
  country_code: string;
  contact: string;

  status: string;
  statuses: ApplicationStatus[];
  status_reason: string | null;

  answers: Answer[];
  comments: Comment[];

  created_at: string;
  last_user_id: string;
  last_interaction_date: string;

  last_opened_at: string;
}

export interface ApplicationWithLocation extends Application {
  job_location: string;
}

export interface ApplicationSearchEntity {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  job_title: string;
  job_location: string;
}

export interface Answer {
  id: string;
  question_label: string;
  answer: string;
}

export interface Question {
  id: string;
  label: string;
}

export interface Candidate {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export const Anonyme: Candidate = {
  id: "",
  first_name: "*****",
  last_name: "*****",
  email: "*****",
  phone: "*****",
};

export enum StatusCategories {
  new = "new",
  processing = "processing",
  finalized = "finalized",
  closed = "closed",
  did_not_answer = "did_not_answer",
  talent_pool = "talent_pool",
  // if you add a new one here, don't forget to update ApplicationCategoryToStyle
}

export interface Status {
  id: string;
  label: string;
  category: StatusCategories;
}

export interface ApplicationStatus {
  id: string;
  label: string;
  user_id: string;
  is_bulk_action: boolean;
  created_at: string;
}

export enum CommentKind {
  COMMENT = "COMMENT",
  INTERVIEW_CANCELLED = "INTERVIEW_CANCELLED",
}

export interface Comment {
  id: string;
  content: string;
  user_id: string;
  is_bulk_action: boolean;
  created_at: string;
  kind: CommentKind;
}

// needed for the application history, derived from the ats export history
export interface ApplicationExportHistory {
  id: string;
  status: string;
  user_id: string;
  created_at: string;
}

export type CandidatesObject = { [x: string]: Candidate };

export type ApplicationsPerStatuses = {
  id: number | string;
  value: number;
  label: string;
};

export type ApplicationsReport = {
  hired_applications_count: number;
  qualified_applications_count: number;
  applications_processed_count: number;
  applications_processed_time: number;
  applications_per_statuses: Array<ApplicationsPerStatuses> | null;
  applications_hire_time: number;
  applications_interview_time: number;
};

export type ApplicationHistory =
  | Comment
  | ApplicationStatus
  | ApplicationExportHistory
  | MessagingHistoryReminder
  | RecruiterReminder
  | Interview
  | InterviewWasDone
  | CallHistory
  | DocumentsLogs
  | ApplicationShare;

export interface ApplicationStatusReasons {
  refused:
    | "salary"
    | "schedule"
    | "unavailable"
    | "skills"
    | "behavior"
    | "location"
    | "other"
    | "contract"
    | "motivation";
  validationPending: "documents" | "interview_slot" | "manager_feedback" | "candidate_answer" | "other";
}

export type ApplicationStatusReasonSource = "recruiter" | "candidate";

export interface ApplicationsCountForJobByStatus {
  status_id: string;
  label: string;
  value: number;
}

export interface ApplicationsCountPerJob {
  job_id: string;
  applications_count: number;
  reports: ApplicationsCountForJobByStatus[];
}

export enum DocumentsLogsType {
  REQUEST = "REQUEST",
  UPLOAD = "UPLOAD",
  AUTOMATION = "AUTOMATION",
  SELF_UPLOAD = "SELF_UPLOAD",
}

export type DocumentsLogs = {
  log_type: DocumentsLogsType;
  document_type: string;
  created_at: string;
  user_id: string;
};

export type ApplicationShare = {
  created_at: string;
  user_id: string;
  recipients: Array<string>;
};

export interface ApplicationListItem {
  id: string;

  organization_name: string;
  campaign_id: string;
  job?: Job;
  candidate?: Candidate;

  status: string;
  status_reason: string | null;
  last_interaction_date: string;

  created_at: string;
  last_opened_at: string;

  answers: Answer[];
}
